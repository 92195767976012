/**
 * = Cards
 */

.card {
    position: relative;
    .card-header{
        background: transparent;
    }

    &.hover-state{
        &:hover {
            background-color: $soft;
        }
    }

    .profile-cover{
        @include background-image(no-repeat, cover); 
        height: 200px;
    }

}

.message-wrapper,
.task-wrapper{
    .card{
        border:0;
    }
}

.customer-testimonial {
    .content-wrapper{
        &:before {
            content: "";
            position: absolute;
            top: 25px;
            left: -1px;
            margin-left: .75rem;
            border: 8px solid $black;
            border-color: transparent #e0e6ec #e0e6ec transparent;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            @include transform(rotate(135deg));
            @include box-shadow($box-shadow);
        }
    }
}

.card-footer{
    background-color:transparent;
}

.card-stats{
    padding-left:1.9rem;
}


// Card with blockquotes

.card {
    .card-blockquote {
        position: relative;
        padding: 2rem;

        .svg-bg {
            display: block;
            position: absolute;
            width: 100%;
            height: 95px;
            top: -94px;
            left: 0;
        }
    }
}

// Animated cards


.page-preview {

    display: block;
    position: relative;

    .show-on-hover {
        position: absolute;
        bottom: -0;
        background: rgba($dark, .85);
        padding: 10px 0;
        width: 100%;
        left: 0;
        opacity: 0;
        @include transition(.2s);
    }

    &:hover {
        z-index: 1;
        
        .show-on-hover {
            opacity: 1;
        }
    }
}

.theme-settings {
    z-index: 99;
    position: fixed;
    right: 15px;
    bottom: 0;
    
    &, .card-body {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    max-width: 400px;
}

.theme-settings-close {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: .65rem;
}

.btn-close {

    &.theme-settings-close{
        background: transparent escape-svg($btn-close-bg-white) center / $btn-close-width auto no-repeat; // include transparent for button elements
        border: 0; // for button elements
        @include border-radius();
        opacity: $btn-close-opacity;
    }
}

.theme-settings-expand {
    right: 15px;
    bottom: 0;
    height: 0;
    @include transition(.3s height);

    &.show {
        height: 40px;
    }

    &:hover {
        background: $gray-600;
        cursor: pointer;
    }
}
